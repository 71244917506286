import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_COFINANCIAMIENTO+'/alianzas');

export default {
  obtener_alianzas() {
    return http.get('/');
  },
  obtener_alianza(id) {
    return http.get(`/${id}`);
  },
  crear_alianza(payload) {
    return http.post('/', payload);
  },
  editar_alianza(id, payload) {
    return http.put(`/${id}`, payload);
  },
  crear_usuario(alianza_id, payload) {
    return http.post(`/${alianza_id}/usuarios`, payload);
  },
  actualizar_usuario(alianza_id, usuario_id, payload) {
    return http.put(`/${alianza_id}/usuarios/${usuario_id}`, payload);
  },
  usuarios_sincronizar(alianza_id, payload) {
    return http.post(`/${alianza_id}/usuarios/sync`, payload);
  },
  sincronizar_documentos(alianza_id, payload) {
    return http.post(`/${alianza_id}/documentos/sync`, payload);
  }
}