<template>
  <div>
    <div class="row">
      <div class="col-sm-2 offset-sm-10">
        <button class="btn btn-primary" @click="modal_tipos=true">Agregar tipo</button>
      </div>
    </div>
    <div v-if="documentos_datos.length > 0" class="tipos_archivos">
      <div v-for="(tipo, index) in documentos_datos" class="tipo">
        <div class="icono"><i class="fa-solid fa-xmark" title="Eliminar documento" @click="quitar_tipo(index)"></i></div>
        <div class="nombre">{{ tipo.nombre }}</div>
      </div>
    </div>
    <div class="tipos_archivos">
      <div class="text-center">No hay documentos agregados</div>
    </div>

    <Tipo v-if="modal_tipos" :tipos="tipos_archivos" :alianza="alianza_tmp" @close="modal_tipos=false" @update="agregar_tipos" />
  </div>
</template>

<script>
import Tipo from './Documento/Tipo';

import BpbCheckbox from '@/components/Form/Checkbox';

import apiOtros from '@/apps/fyc/api/otros';

export default {
  components: {
    BpbCheckbox, Tipo
  },
  props: {
    alianza: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alianza_tmp: {
        documentos:[]
      },
      tipos_archivos: [],
      filtrar_tipo: null,
      tipos_seleccionados: [],
      modal_tipos: false
    }
  },
  mounted() {
    this.alianza_tmp = this.$helper.clone(this.alianza);
    if (this.alianza_tmp.documentos && this.alianza_tmp.documentos.length > 0) {
      this.tipos_seleccionados = [];
      this.alianza_tmp.documentos.forEach(tipo => {
        this.tipos_seleccionados.push(tipo.tipo);
      })
    }

    this.obtener_tipos();
  },
  methods: {
    async obtener_tipos() {
      try {
        this.tipos_archivos = (await apiOtros.obtener_tipos_archivos()).data.opciones;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async agregar_tipos(tipos) {
      this.alianza_tmp.documentos = this.alianza_tmp.documentos.concat(tipos);
      this.modal_tipos = false;
    },
    quitar_tipo(index) {
      this.alianza_tmp.documentos.splice(index,1);
    }
  },
  computed: {
    documentos_datos() {
      let documentos = this.$helper.clone(this.alianza_tmp.documentos);
      for(let i=0; i<documentos.length; i++) {
        for(let a=0; a<this.tipos_archivos.length; a++) {
          if (this.tipos_archivos[a].valor == documentos[i].tipo) {
            documentos[i].nombre = this.tipos_archivos[a].nombre;
          }
        }
      }

      return documentos;
    }
  },
  watch: {
    alianza_tmp: {
      handler(val) {
        this.$emit('update', val);
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tipos_archivos {
  .tipo {
    width: 200px;
    border: solid 1px grey;
    border-radius: 3px;
    margin: 10px;
    padding: 3px;
    display: inline-block;
    vertical-align: top;

    .icono,.nombre {
      display: inline-block;
    }

    .icono {
      width: 26;
      color: red;
      padding: 3px 8px;
      vertical-align: top;
    }

    .nombre {
      width: calc(100% - 26px);
    }
  }
}
</style>