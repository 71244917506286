<template>
  <div>
    <div class="row form-group">
      <div class="col-sm-12">
        <label for="nombre" class="col-form-label">Nombre</label>
        <input v-model="alianza_general.nombre" type="text" name="nombre" id="nombre" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-12">
        <label for="descripcion" class="col-form-label">Descripción</label>
        <textarea v-model="alianza_general.descripcion" name="descripcion" id="descripcion" cols="30" rows="5" class="form-control"></textarea>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-12">
        <label for="estatus" class="col-form-label">Estatus</label>
        <select v-model="alianza_general.estatus" name="estatus" id="estatus" class="form-control">
          <option value="activo">Activo</option>
          <option value="inactivo">Inactivo</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alianza: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alianza_general: {
        nombre: null,
        descripcion: null,
        estatus: 'activo',
      }
    }
  },
  mounted() {
    this.alianza_general = this.alianza;
  },
  watch: {
    alianza(val) {
      this.alianza_general = val;
    },
    alianza_general(val) {
      this.$emit('update', val);
    }
  }
}
</script>