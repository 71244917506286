<template>
  <div>
    <Modal>
      <div class="title">Alianza: {{ alianza.id ? alianza.nombre : 'Nueva' }}</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-3 col-lg-2">
            <ul class="nav nav-pills flex-column">
              <li class="nav-item"><a href="#" :class="'nav-link'+(tab_index == 'general' ? ' active' : '')" @click.prevent="tab_index='general'">General</a></li>
              <li class="nav-item"><a href="#" :class="'nav-link'+(tab_index == 'usuarios' ? ' active' : '')" @click.prevent="tab_index='usuarios'">Usuarios</a></li>
              <li class="nav-item"><a href="#" :class="'nav-link'+(tab_index == 'documentos' ? ' active' : '')" @click.prevent="tab_index='documentos'">Documentos</a></li>
            </ul>
          </div>
          <div class="col-sm-9 col-lg-10">
            <General v-if="tab_index == 'general'" :alianza="alianza" @update="actualizar_alianza" />
            <Usuario v-if="tab_index == 'usuarios'" :alianza="alianza" @update="actualizar_alianza" />
            <Documento v-if="tab_index == 'documentos'" :alianza="alianza" @update="actualizar_alianza" />
          </div>
        </div>
      </div>
      <div class="footer text-right">
        <button class="btn btn-primary mr-2" @click="guardar_alianza">Guardar</button>
        <button class="btn btn-danger" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/fyc/api/alianzas';

import General from './General'
import Usuario from './Usuario'
import Documento from './Documento'

export default {
  components: {
    Modal, General, Usuario, Documento
  },
  props: {
    alianza_id: {
      type: String,
    }
  },
  data() {
    return {
      tab_index: 'general',
      alianza: {
        id:null,
        nombre:null,
        estatus:'activo',
        usuarios:[],
        documentos:[]
      }
    }
  },
  mounted() {
    if (this.alianza_id)
      this.obtener_alianza();
  },
  methods: {
    async obtener_alianza() {
      try {
        this.alianza = (await api.obtener_alianza(this.alianza_id)).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async guardar_alianza() {
      try {
        let res;
        if (this.alianza.id)
          res = (await api.editar_alianza(this.alianza.id, this.alianza)).data;
        else
          res = (await api.crear_alianza(this.alianza)).data;

        this.alianza.id = res.id;

        if (this.alianza.usuarios && this.alianza.usuarios.length > 0)
          for(let usuario of this.alianza.usuarios) {
            if (!usuario.id)
              await api.crear_usuario(this.alianza.id, usuario);
            else
              await api.actualizar_usuario(this.alianza.id, usuario.id, usuario);
            }
        
        if (this.alianza.documentos && this.alianza.documentos.length > 0)
            await api.sincronizar_documentos(this.alianza.id, this.alianza);
          
        this.$log.info('res', res);
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    actualizar_alianza(alianza) {
      this.alianza = alianza;
    }
  }
}
</script>